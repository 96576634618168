<template>
    <component
        :is="href ? 'a' : 'button'"
        :href="href"
        class="inline-flex items-center rounded-md border-transparent text-sm font-semibold tracking-widest text-blue-50 transition duration-150 ease-in-out hover:bg-blue-950 focus:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 bg-gradient-to-r from-blue-600 to-blue-800 hover:from-blue-800 hover:to-blue-950 shadow-md active:bg-gray-950 active:scale-95"
        :class="['btn', additionalClasses, { 'block': block, 'auto-shine': autoShine }]"
    >
        <slot />
    </component>
</template>

<script>
export default {
    props: {
        additionalClasses: {
            type: String,
            default: ''
        },
        href: {
            type: String,
            default: null
        },
        block: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            autoShine: false
        };
    },
    mounted() {
        this.startAutoShine();
    },
    beforeDestroy() {
        clearInterval(this.shineInterval);
    },
    methods: {
        startAutoShine() {
            this.shineInterval = setInterval(() => {
                this.autoShine = true;
                setTimeout(() => {
                    this.autoShine = false;
                }, 650); // Match the duration of the shine effect
            }, 10000); // Every 10 seconds
        }
    }
}
</script>

<style scoped>
.btn {
    text-decoration: none;
    border: 0px solid rgb(146, 175, 248);
    position: relative;
    overflow: hidden;
    padding: 0.75rem 1rem; /* Default padding */
}

.btn:hover{
     box-shadow: 1px 1px 25px 10px rgba(146, 167, 248, 0.4); 
}

.btn:before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        120deg,
        transparent,
        rgba(146, 148, 248, 0.4),
        transparent
    );
    transition: all 650ms;
}

.btn:hover:before,
.auto-shine:before {
    left: 100%;
}

.block {
    display: block;
    width: 100%;
    text-align: center;
}
</style>
